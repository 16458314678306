<template>
  <div>
    <Head></Head>
    <!--  轮播  -->
    <ComCarousel></ComCarousel>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import ComCarousel from '@/components/ComCarousel'

export default {
  name: 'Index',
  components: { ComCarousel, Head, Footer },
  props: {},
  data () {
    return {}
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>

</style>
